import { useEffect, useState } from 'react';
import GuestLayout from '@/Layouts/GuestLayout';
import InputError from '@/Components/InputError';
import { Head, Link, useForm } from '@inertiajs/react';
import { Box, HStack, VStack, Stack, Text, Button, ButtonGroup} from '@chakra-ui/react'

export default function Login({ status, canResetPassword }) {
    const { data, setData, post, processing, errors, reset } = useForm({
        email: '',
        password: '',
        remember: false,
    });

    const [error, setError] = useState({})

    useEffect(() => {
        return () => {
            reset('password');
        };
    }, []);

    const submit = (e) => {
        e.preventDefault();

        post(route('login'), {
            onSuccess: (resp) => {
                const error = resp.props.errorBags.default
                //onToast('success', 'Please kindly make payment on the next screen')
                if(error.hasOwnProperty('email')){
                    setError({
                        email: error.email
                    })
                    return
                }

                if(error.hasOwnProperty('password')){
                    setError({
                        password: error.password
                    })
                    return
                }

                const error_ = error?.error ? error?.error : 'You encountered an error. Please try again'
                setError(error_)
            },
            onError: (error) => {
                
                if(error.hasOwnProperty('email')){
                    setError(error.email)
                    return
                }

                if(error.hasOwnProperty('password')){
                    setError(error.password)
                    return
                }

                const error_ = error?.error ? error?.error : 'You encountered an error. Please try again'
                setError(error_)
            }
        });
    };

    return (
        <GuestLayout>

            <h2 className="page-ath-heading brand-text">Sign in <small className='text-white'>with your PixelVaults Account</small></h2>
            <form action="#" className='text-white'>
                <div className="input-item">
                    <input 
                        id="email"
                        type="email"
                        name="email"
                        value={data.email}
                        autoComplete="username"
                        isfocused={true}
                        onChange={(e) => setData('email', e.target.value)}
                        className="input-bordered" 
                        placeholder='Enter Email Address'
                    />
                    <InputError message={error?.email} className="mt-2 text-danger" />
                </div>
                <div className="input-item">
                    <input 
                        id="password"
                        type="password"
                        name="password"
                        value={data.password}
                        autoComplete="current-password"
                        onChange={(e) => setData('password', e.target.value)}
                        className="input-bordered" 
                        placeholder='Enter Password'
                    />
                    <InputError message={error?.password} className="mt-2 text-danger" />
                </div>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="input-item text-left">
                        <input checked={data.remember} onChange={(e) => setData('remember', e.target.checked)} className="input-checkbox input-checkbox-md" id="remember-me" type="checkbox" />
                        <label htmlFor="remember-me" className='text-white'>Remember Me</label>
                    </div>
                    <div>
                        <Link href={'/forgot-password'}>Forgot password?</Link>
                        <div className="gaps-2x" />
                    </div>
                </div>
                <Button isLoading={processing} onClick={submit} className="btn btn-primary btn-block brand-bg border-0 text-dark">Sign In</Button>
            </form>

            <div className="gaps-2x" />
            <div className="gaps-2x" />
            <div className="form-note text-white">Don’t have an account?
                <Link href={'/register'}> <strong>Sign up here</strong></Link>
            </div>


        </GuestLayout>
    );
}
